

























  import { PAGE_CONTENT_TYPE } from '@/constant/Enums';
  import { ServerResponse } from '@/services/response.types';
  import { PartnerStateInterface } from '@/store/partner/types';
  import { ContentModel } from '@/store/types';
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Action, State } from 'vuex-class';

  @Component({
    name: 'Partner',
    components: {
      protectNow: () => import('@/components/ProtectNow.vue'),
    },
    mixins: [],
  })
  export default class Partner extends Vue {
    @Action('GetPartner', { namespace: 'partner' }) GetPartner!: () => Promise<ServerResponse>;

    @State((state) => state.partner) partner!: PartnerStateInterface;
    @State('page_content') page_content!: ContentModel[];

    public get banner(): string {
      return this.page_content.filter((item) => item.type === PAGE_CONTENT_TYPE.REKAN)[0]?.image ?? this.partner.main_banner;
    }

    public get providers() {
      return this.partner.providers.map((partner) => {
        partner.detail_ind_trunc = partner.detail_ind.slice(0, 200) + '...';
        partner.detail_eng_trunc = partner.detail_eng.slice(0, 200) + '...';
        return partner;
      });
    }

    public created(): void {
      this.init();
    }

    private init() {
      this.GetPartner().catch((res) => (res?.status?.is_valid === false ? this.init() : false));
    }

    public mounted(): void {
      // on componenet mounted
    }
  }
